<template>
  <div class="page-wrap">
    <base-card class="pl-9 pr-9">
      <v-card-title>{{message}}</v-card-title>
      <v-card-subtitle>비밀번호 변경이 필요합니다.</v-card-subtitle>
      <v-card-text>
        <v-row dense>
          <v-col><v-text-field v-model="item.currPwd" type="password" label="현재 비밀번호" /></v-col>
        </v-row>
        <v-row dense>
          <v-col><v-text-field v-model="item.newPwd" type="password" label="신규 비밀번호" placeholder="영문, 숫자, 특수문자 조합 8자 이상 입력" /> </v-col>
        </v-row>
        <v-row dense>
          <v-col><v-text-field v-model="item.rePwd" type="password" label="비밀번호 확인" /></v-col>
        </v-row>
        <v-row dense>
          <v-spacer />
          <v-btn small color="info" @click.stop="changePassword" :disabled="btnDisabled">비밀번호 변경</v-btn>
          <v-spacer />
        </v-row>
      </v-card-text>
    </base-card>
  </div>
</template>
<script>
import api from '@/services/api'
import notify from '@/utils/notify'
export default {
  name: 'PasswordChange',
  metaInfo: {
    title: '비밀번호 변경'
  },
  props: {
    loginId: { type: String, default: null },
    errorCode: { type: String, default: null }
  },
  data() {
    return {
      message: null,
      item: {},
    }
  },
  methods: {
    changePassword() {
      if (this.loginId) {
        api.pwdChangeOnLogin(this.item, this.loginId, this.errorCode === '3201' ? 'init' : 'expire', (error) => {
          if (error.response) {
            switch (error.response.data.message) {
              case 'PWD_RULE_VIOLATION_1': notify.showError('영문, 숫자, 특수문자 조합 8자 이상 입력 하십시오.'); break
              case 'PWD_RULE_VIOLATION_2': notify.showError('연속된 숫자나 문자를 사용할 수 없습니다.'); break
              case 'PWD_RULE_VIOLATION_3': notify.showError('사용자 아이디가 3자 이상 포함되어 있습니다.'); break
              case 'PWD_RULE_VIOLATION_4': notify.showError('최근 4회이내 사용된 비밀번호 입니다.'); break
              default : notify.showError('사용할 수 없는 비밀번호 입니다.'); break
            }
          }
        }).then(r => {
          this.$router.push('/')
        })
      }
    },
  },
  computed: {
    btnDisabled() {
      if (!this.item.currPwd || this.item.currPwd.length < 8) return true
      if (!this.item.newPwd || this.item.newPwd.length < 8) return true
      if (!this.item.rePwd || this.item.rePwd.length < 8) return true
      if (this.item.newPwd !== this.item.rePwd) return true
      return false
    }
  },
  watch: {
    errorCode() {
      switch (this.errorCode) {
        case 3209:
        case 3299:
        case 3201:
          this.message = '비밀번호가 만료 되었습니다.'
          break
        case 201:
          this.message = '비밀번호가 초기화 되었습니다.'
      }
      this.message = '잘못된 접근 입니다.'
    }
  }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
